<!-- Copied from https://github.com/kevinongko/vue-numeric with bug fixes & improvements -->
<template>
  <input 
    :placeholder="placeholder"
    @blur="onBlurHandler"
    @input="onInputHandler"
    @focus="onFocusHandler"
    @keypress="numbersOnly($event)"
    ref="numeric"
    type="tel"
    v-model="amount"
    :readonly="readonly">
</template>

<script>
import accounting from 'accounting-js'
import { getCurrency } from '@/components/currency'

export default {
  name: 'VueNumeric',
  props: {
    /**
     * symbol. e.g $,%
     * This takes precedence over locale
     */
    symbol: {
      default: '',
      required: false,
      type: String
    },
    /**
     * Maximum value allowed.
     */
    max: {
      default: Number.MAX_SAFE_INTEGER || 9007199254740991,
      required: false,
      type: Number
    },
    /**
     * Minimum value allowed.
     */
    min: {
      default: Number.MIN_SAFE_INTEGER || -9007199254740991,
      required: false,
      type: Number
    },
    /**
     * Enable/Disable minus value.
     */
    minus: {
      default: false,
      required: false,
      type: Boolean
    },
    /**
     * Input placeholder.
     */
    placeholder: {
      required: false,
      type: String
    },
    /**
     * Number of decimals.
     * Decimals symbol are the opposite of separator symbol.
     */
    precision: {
      required: false,
      type: Number
    },
    /**
     * Thousand separator type.
     * Separator props accept either . or , (default).
     */
    separator: {
      default: ',',
      required: false,
      type: String
    },
    /**
     * v-model value.
     */
    value: {
      default: 0,
      required: true,
      type: [Number, String]
    },
    readonly: {
      default: false,
      required: false,
      type: Boolean
    },
    /**
     * Class for the span tag when readOnly props is true.
     */
    readOnlyClass: {
      default: '',
      required: false,
      type: String
    },
    /**
     * Position of symbol e.g. $, %
     * Symbol position props accept either 'suffix' or 'prefix' (default).
     */
    symbolPosition: {
      default: 'prefix',
      required: false,
      type: String
    },
    /**
     * To format numbers based on locale. symbol take precedence over locale
     */
    locale: {
      default: '',
      required: false,
      type: String
    },
    /**
     * Use in conjunction with locale
     * Possible values are currency, percent, decimal
     */
    localStyle: {
      default: 'currency', // Check https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
      required: false,
      type: String
    }
  },
  data: () => ({
    amount: ''
  }),
  computed: {
    /**
     * Number type of formatted value.
     * @return {Number}
     */
    amountNumber() {
      return this.unformat(this.amount)
    },
    /**
     * Number type of value props.
     * @return {Number}
     */
    valueNumber() {
      return this.unformat(this.value)
    },
    /**
     * Define decimal separator based on separator props.
     * @return {String} '.' or ','
     */
    decimalSeparator() {
      // if (this.separator === ',') return '.'
      return '.'
    },
    /**
     * Define thousand separator based on separator props.
     * @return {String} '.' or ','
     */
    thousandSeparator() {
      if (this.separator === '.') return '.'
      if (this.separator === 'space') return ' '
      if (this.separator === '') return ''
      return ','
    },
    /**
     * Define format position for currency symbol and value.
     * @return {String} format
     */
    innerSymbolPosition() {
      if (!this.symbol) return '%v'
      return this.symbolPosition === 'suffix' ? '%v %s' : '%s %v'
    },
    watchGroup() {
      return {
        separator: this.separator,
        symbol: this.symbol,
        precision: this.precision,
        locale: this.locale
      }
    }
  },
  watch: {
    /**
     * Watch for value change from other input with same v-model.
     * @param {Number} newValue
     */
    valueNumber(newValue, oldValue) {
      if (this.$refs.numeric !== document.activeElement) {
        this.amount = this.format(newValue)
      }
      if (newValue !== oldValue) {
        this.$emit('value-changed')
      }
    },
    /**
     * Immediately reflect separator, symbol & precision changes
     */
    watchGroup() {
      this.process(this.valueNumber)
      this.amount = this.format(this.valueNumber)
    }
  },
  mounted() {
    this.process(this.valueNumber)
    this.amount = this.format(this.valueNumber)
  },
  methods: {
    /**
     * Handle blur event.
     * @param {Object} e
     */
    onBlurHandler(e) {
      this.$emit('blur', e)
      this.amount = this.format(this.valueNumber)
    },
    /**
     * Handle focus event.
     * @param {Object} e
     */
    onFocusHandler(e) {
      this.$emit('focus', e)
      if (this.valueNumber === 0) {
        this.amount = null
      } else if (this.readonly) {
        return false
      } else {
        this.amount = accounting.formatMoney(this.valueNumber, {
          symbol: '',
          format: '%v',
          thousand: '',
          decimal: this.decimalSeparator,
          precision: Number(this.precision)
        })
        this.$nextTick(function() {
          e.target.select()
        })
      }
    },
    /**
     * Handle input event.
     */
    onInputHandler() {
      this.process(this.amountNumber)
    },
    /**
     * Validate value before update the component.
     * @param {Number} value
     */
    process(value) {
      if (value >= this.max) this.update(this.max)
      if (value <= this.min) this.update(this.min)
      if (value > this.min && value < this.max) this.update(value)
      if (!this.minus && value < 0) this.min >= 0 ? this.update(this.min) : this.update(0)
    },
    /**
     * Update parent component model value.
     * @param {Number} value
     */
    update(value) {
      this.$emit('input', Number(accounting.toFixed(value, this.precision)))
    },
    /**
     * Format value using symbol and separator.
     * @param {Number} value
     * @return {String}
     */
    format(value) {
      if (this.locale && !this.symbol) {
        // TODO: Need to do roundAwayFromZero on value
        return new Intl.NumberFormat(this.locale, {
          style: 'currency',
          currency: getCurrency(this.locale),
          minimumFractionDigits: this.precision
        }).format(value)
      } else {
        return accounting.formatMoney(value, {
          symbol: this.symbol,
          format: this.innerSymbolPosition,
          precision: Number(this.precision),
          decimal: this.decimalSeparator,
          thousand: this.thousandSeparator
        })
      }
    },
    /**
     * Remove symbol and separator.
     * @param {Number} value
     * @return {Number}
     */
    unformat(value) {
      return accounting.unformat(value, this.decimalSeparator)
    },
    /**
     * Allow numeric [0-9] and '.' only
     * @param {Event} event
     * @return {Boolean}
     */
    numbersOnly(event) {
      event = event || window.event
      var charCode = event.which ? event.which : event.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode !== 46 || this.precision === 0) && !(this.minus && charCode === 45)) {
        event.preventDefault()
      } else {
        return true
      }
    }
  }
}
</script>
